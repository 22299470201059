<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :z-index="9" :loader-status="preloader"/>
    <v-container fluid>
      <v-card class="mb-6 pa-5">
        <h2 class="title mb-4">{{ $t('document.edit.title') }}</h2>
        <v-row>
          <v-col lg="12">
            <v-text-field
                v-model="form.name"
                outlined
                hide-details
                :label="$t('document.name')"
                :placeholder="$t('document.name')"
                required
                :error="errors.name!==undefined"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4">
            <v-select
                outlined
                v-model="form.for"
                :items="select_for"
                :label="$t('document.for.select')"
                :append-icon="'mdi-chevron-down'"
                hide-details
                @waiting="filterCategory"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="changeForFor"
            ></v-select>
          </v-col>
          <v-col lg="4">
            <v-select
                outlined
                v-model="form.category_id"
                :items="filterCategory"
                :label="$t('document.category.select')"
                :append-icon="'mdi-chevron-down'"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
            ></v-select>
          </v-col>
          <v-col lg="4">
            <v-select
                outlined
                v-model="form.for_all"
                :items="select_for_all"
                :label="$t('document.for_all.select', {element: $t(`document.for.${form.for}`)})"
                :append-icon="'mdi-chevron-down'"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>

      <v-card style="position:relative;" class="pa-5 mb-6" v-if="!form.for_all && (form.for === 'employee' || form.for === 'all')">
        <h4 class="mb-6">{{ $t('document.employee.title') }}</h4>
        <MultiSelectEmployee :selected="form.employees" @result="resultSelectEmployees"/>
      </v-card>

      <v-card style="position:relative;" class="pa-5 mb-6" v-if="!form.for_all && (form.for === 'customer' || form.for === 'all')">
        <h4 class="mb-6">{{ $t('document.customer.title') }}</h4>
        <MultiSelectCustomer :selected="form.customers" @result="resultSelectCustomers"/>
      </v-card>

      <v-card class="pa-5">
        <v-row>
          <v-col cols="12" sm="6" md="3" lg="2" class="pr-0 ml-auto">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                color="green"
                @click="documentCreate"
                :loading="btn_create_loading"
                :disabled="btn_create_loading"
            >
              {{ $t('document.category.buttons.save') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                @click="backToDocuments"
            >
              {{ $t('document.category.buttons.abort') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MultiSelectEmployee from "@/components/Employee/MultiSelectEmployee";
import MultiSelectCustomer from "@/components/Customer/MultiSelectCustomer";

export default {
  components: {MultiSelectEmployee, MultiSelectCustomer},
  data() {
    return {
      preloader: true,
      preloader_employee: true,
      btn_create_loading: false,
      form: {
        name: '',
        employees: [],
        customers: [],
        teams: [],
        roles: [],
        for: 'employee',
        for_all: 1,
        category_id: 1
      },
      select_for: [
        {
          text: this.$t('document.for.employee'),
          value: 'employee'
        },
        {
          text: this.$t('document.for.customer'),
          value: 'customer'
        },
        {
          text: this.$t('document.for.all'),
          value: 'all'
        },
      ],
      select_category: [],
      select_for_all: [
        {
          text: this.$t('document.for_all.yes'),
          value: 1
        },
        {
          text: this.$t('document.for_all.no'),
          value: 0
        },
      ],
      errors: []
    }
  },
  computed: {
    filterCategory() {
      return this.select_category.filter( category => {
        if (this.form.for === 'all') {
          return category.employee === 1 && category.customer === 1
        }

        if (this.form.for === 'employee') {
          return category.employee === 1
        }

        if (this.form.for === 'customer') {
          return category.customer === 1
        }
      })
    },
  },
  created () {
   this.getCategories()
  },
  methods: {
    changeForFor() {
      this.form.category_id = 1
    },
    resultSelectEmployees(employees) {
      this.form.employees = employees
    },
    resultSelectCustomers(customers) {
      this.form.customers = customers
    },
    getCategories() {
      this.$store.dispatch('documentCategories').then(() => {
        this.select_category = this.$store.getters.document_categories
        this.getDocument()
      })
    },
    getDocument() {
      this.$store.dispatch('documentEdit', this.$route.params.id).then(() => {
        this.form = this.$store.getters.document
        this.preloader = false
      })
    },
    documentCreate() {
      this.btn_create_loading = true

      if (this.form.for_all) {
        this.form.employees = []
        this.form.customers = []
        this.form.teams = []
        this.form.roles = []
      }
      this.$store.dispatch('documentUpdate', this.form)
          .then(() => {this.backToDocuments()})
          .catch(error => {
            this.btn_create_loading = false
            switch (error.response.status) {
              case 422:
                this.errors = error.response.data.errors;
                break;
            }
          })
    },
    backToDocuments() {
      this.$router.go(-1)
    }
  }
}
</script>
